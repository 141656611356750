import React, { useEffect, useState } from "react";
import "./RateQuote.css";

import { commaizeFloat } from "../../Common";

import { Table,Tabs, Switch, Popover, Button, Modal, Tooltip, Input, Select,Row, Col, Divider, Checkbox} from 'antd';
import { CodeSandboxCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import Lenders from "../../Management/Lenders";
import CompareProduct from "./CompareProductModal";
import { checkAccess } from "../../Auth";
import { UR_Owner, UR_LoanOfficer, UR_PriceEngineUser } from "../../State";
import * as com from "../../Common";


const {TabPane}=Tabs;



const typemap = {
  fixed30: "30 Year",
  fixed25: "25 Year",
  fixed20: "20 Year",
  fixed15: "15 Year",
  fixed10: "10 Year",
  arm56: "ARM 5/6",
  arm76: "ARM 7/6",
  arm106: "ARM 10/6",
}

const loanTermLabelMap = {
  fixed30: "30 Year Fixed",
  fixed25: "25 Year Fixed",
  fixed20: "20 Year Fixed",
  fixed15: "15 Year Fixed",
  fixed10: "10 Year Fixed",
  arm56: "5/6 ARM",
  arm76: "7/6 ARM",
  arm106: "10/6 ARM",
}

const skipKeywordsMap={
  "Agency": ["conforming","highbalance", "high-balance","high_balance","super_conforming","superconforming","super-conforming","agency"],
  "Jumbo": ["jumbo","fha_jumbo","va_jumbo"],
  "FHA": ["fha","fha_high_balance","fha_jumbo"],
  "VA": ["va","va_high_balance","va_jumbo"],
  "USDA": ["usda"],
  "Non-QM": ["nonqm", "non-qm","non_qm"],
}

export function AllRatesTable(props) {
  const [emptyTargetRate,setEmptyTargetRate] = useState(false)
  const [allresults,setAllResults] = useState(0);
  const [connectedresults,setConnectedResults] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState("fixed30");
  const [currentTarget,setCurrentTarget] = useState('price');
  // const [targetprice,setTargetPrice] = useState(3000);
  const [targetrate,setTargetRate] = useState(6.5);
  const [showAddLenderModal, setShowAddLendersModal] = useState(false);
  const [useDiscountFormat, setUseDiscountFormat] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showShareRateModal, setShowShareRatesModal] = useState(false);
  const [compareModal, setCompareModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { TextArea } = Input;
  
  let AllresultsCount={}
  let ConnectedresultsCount={}
  useEffect(() => {
    setAllResults(AllresultsCount[activeTabKey]);
    setConnectedResults(ConnectedresultsCount[activeTabKey])
  }, [activeTabKey,props.productTypes])

  const handleTabChange=(key)=>{
    setActiveTabKey(key);
  };
  const getCurrentDate = () =>{
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var today = new Date();

    const dayOfWeek = today.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // If today is Sunday (0), subtract 2 days to get Friday
      // If today is Saturday (6), subtract 1 day to get Friday
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek === 0 ? 2 : 1));
    }

    return today.toLocaleDateString("en-US", options);
  }

  const getRatesAfterSpecials = (record) => {
    if (record.Name.includes("Wh_Cnf")) {
      return (record.rate+props.specialAdjustments['Total']+0.375).toFixed(3)
    }
    let totaladdback = 0
    if (record.Name.includes("Community")){
      if (Object.keys(props.specialAdjustments).includes("CRA")){
        totaladdback += 0.75
      }
      if (Object.keys(props.specialAdjustments).includes("Holiday")){
        totaladdback += 0.25
      }
      return (record.rate+props.specialAdjustments['Total']+totaladdback).toFixed(3)
    }
  }
  const handleSelectRow = (record) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.key)
      ? selectedRowKeys.filter((key) => key !== record.key)
      : [...selectedRowKeys, record.key]
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const maincolumns = [
    {
      title: 'Lender & Product',
      dataIndex: 'program',
      key: 'program',
      width:"150px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <Tooltip
          overlayClassName='rates-lender-tooltip'
          arrow={false}
          placement="bottomLeft"
          title={
            <div>
              <div style={{fontFamily: 'Inter', fontSize:"16px", color:"#FFF", lineHeight: '20px'}}>{`Last Updated on ${getCurrentDate()} 09:00`}</div>
            </div>
          }
        >
          <div>
            <Row><div style={{fontSize:"16px", fontFamily: 'Inter', color: '#222', lineHeight: '20px', fontWeight: 500}}>{com.divideStringByCapitalLetter(record.Lender) === "GMCC Celebrities"?(record.Name.includes("Wh_Ncf")?"GMCC Celebrity Jumbo": record.Name.includes("Community Opportunity")?"GMCC Community Opportunity" :"GMCC Celebrity"):com.divideStringByCapitalLetter(record.Lender)}</div></Row>
            {props.isCelebrity && props.grantAvailable !== "None" && <div className="grantInfoBulb">{ props.grantAvailable === "10K"? "$10K Grant available" : props.grantAvailable === "5K"?"$5K Grant available":"$30K Grant available"}</div>}
            {props.isCelebrity ? 
              <div style={{overflow:"hidden",width:"180px",fontSize: 16,color:"#222",whiteSpace:"nowrap", textOverflow:'ellipsis', lineHeight: '20px'}}>{record.Name.includes("Community Opportunity")?"No MI": record.Name.includes("Wh_Ncf")?"DTI 45%  No RSU for ARM":""}</div>
            :
              <div style={{overflow:"hidden",width:"180px",fontSize: 16,color:"#222",whiteSpace:"nowrap", textOverflow:'ellipsis', lineHeight: '20px'}}>{record.Name}</div>
            }
            {/* <Row>{record.Name}</Row> */}
            {/* <Row style={{fontSize:"12px", color:"#6E6E70",marginTop:"5px"}}>{`Last Updated on ${getCurrentDate()} 09:00`}</Row> */}
          </div>
        </Tooltip>
      ),
      sorter:(a, b) => a.program.localeCompare(b.program),
      sortDirections: ['descend','ascend','descend'],
    },
    // {
    //   title: 'Product',
    //   dataIndex: 'name',
    //   key: 'product',
    //   width:"95px",
    //   render:(_,record)=>(
    //     <div>
    //       {/* <Row><strong>{com.divideStringByCapitalLetter(record.Lender)}</strong></Row> */}
    //       <div style={{fontSize:"12px",color:"#6E6E70",fontWeight:"400"}}>{record.Name}</div>
    //       {/* <Row style={{fontSize:"12px", color:"#6E6E70",marginTop:"5px"}}>{`Last Updated on ${getCurrentDate()} 09:00`}</Row> */}
    //     </div>
    //   ),
    // },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"70px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <>
          {!props.isCelebrity && <div style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":'#222', fontFamily: 'Inter', fontSize: 16, fontWeight: 600}}>{record.rate.toFixed(3)}%</div>}
          {props.isCelebrity && record.Name.includes("Wh_Ncf") && <div style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":'#222', fontFamily: 'Inter', fontSize: 16, fontWeight: 600}}>{record.rate.toFixed(3)}%</div>}
          {props.isCelebrity && !record.Name.includes("Wh_Ncf") && <>
            <div style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":'#222'}}>{getRatesAfterSpecials(record)}%</div>
            {<Popover 
              content={
                <div>
                  <div className="d-flex justify-content-between pl-2 pr-2 bold">
                    <div>Rate before specials:</div>
                    <div>{record.rate.toFixed(3)}%</div>
                  </div>
                  <div className="pl-2 pr-2" style={{color:"green"}}>
                    {Object.entries(props.specialAdjustments).map(([key,value])=>{
                      if (key !== 'Total') {
                        return (    
                            <div style={{display:record.Name.includes("Community") && key === "CRA"? "none": record.Name.includes("Wh_Cnf") && key==="Market"?"none":record.Name.includes("Community")&& key ==="Holiday"?"none":""}}>{value}% for {key} special adjustment</div>
                        )
                      }
                    })}
                  </div>
                  <hr/>
                  <div className="d-flex justify-content-between pl-2 pr-2 bold" style={{color:"#ff2377"}}>
                    <div>Rate after specials:</div>
                    <div>{getRatesAfterSpecials(record)}%</div>
                  </div>
                </div>
              }
            >
              <div className="specialAdjustments">{"Specials detail"}</div>
            </Popover>
            }
          </>
          }
        </>
      ),
      sorter:(a, b) => {
          return a.rate - b.rate;
      },
      sortDirections: ['ascend','descend',"ascend"],
    },
    {
      title: 'PMT',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"90px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <div>
          <div style={{ fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 500, lineHeight: '20px', marginBottom: 4}}>${commaizeFloat(record.monthlypay)}</div>
          {record.isConnected && <div style={{fontSize: 14, fontFamily: 'Inter', color: com.divideStringByCapitalLetter(record.Lender) === "GMCC Celebrities" && record.dti.toFixed(0) > 45 ?"#C21818":"#6E6E70", lineHeight: '18px'}}>DTI: <span style={{ fontWeight: 500 }}>{record.dti.toFixed(0)}%</span></div>}
        </div>
      ),
      sorter:(a, b) => a.monthlypay - b.monthlypay,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title="The adjusted price is calculated by adding the price adjustment points to the lock day price.">
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img src="/images/rates-price-icon.svg"/> 
          <span>Price</span>
        </div>
      </Tooltip>,
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"130px",
      className: "outerRateColumn",
      render:(_,record)=>{
        let adjustedPrice = useDiscountFormat ? parseFloat(record.originalprice).toFixed(3) +"%" : (100 - parseFloat(record.originalprice)).toFixed(3)
        return <Popover overlayClassName='total-pricing-popover' placement='bottomLeft' content={record.popoverContent} title=''>
          <div style={{display:"flex",alignItems:"center"}}>
            <div style={{textDecoration:"underline", fontFamily: 'Inter', fontSize: 16, fontWeight: 500, color: !record.isConnected?"":record.originalprice<=0 ? "#208029": "#C21818", lineHeight: '20px'}} >
              {adjustedPrice} / ${commaizeFloat(parseFloat(record.originalprice * record.loanAmount*0.01).toFixed(0))}
            </div> 
          </div>
        </Popover>
      },
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title='The compensation point established on the "Lenders" page.'>LO. Comp.</Tooltip>,
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"110px",
      className: "outerRateColumn",
      render:(_,record)=>(
        <div style={{ fontFamily: 'Inter', fontSize: 16, color: '#222',fontWeight: 500, lineHeight: '20px'}}>{record.isConnected ? record.prices.CompensationPoint.toFixed(3) + "% / $"+commaizeFloat(record.compensationprice) : "---"}</div>
      ),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['descend','ascend','descend'],
    },

    {
      title: <Tooltip title='The total price is the sum of the lock day price, adjustment points, and compensation points.'>Net pricing</Tooltip>,
      dataIndex: 'points',
      key: 'points',
      width:"120px",
      className: "outerRateColumn",
      render:(_,record)=>{
        let p = useDiscountFormat ? parseFloat(record.FHAMIP ? record.points + (100*record.FHAMIP.upfrontMIP/record.loanAmount) : record.points).toFixed(3) + "%" : parseFloat(100 - (record.FHAMIP ? record.points + (100*record.FHAMIP.upfrontMIP/record.loanAmount) : record.points)).toFixed(3)
        let price = record.FHAMIP ? (record.lender_credit + record.FHAMIP.upfrontMIP) : record.lender_credit
        return <>
          {record.isConnected? <div style={{ fontFamily: 'Inter'}}>
            <Popover placement='bottomLeft' overlayClassName='total-pricing-popover' content={record.netpricingPopContent} title=''>
              <div style={{display:"flex",alignItems:"center"}}>
                <div style={{fontSize: 16, color: record.lender_credit<=0 ? "#208029":"#C21818", fontWeight: 500, lineHeight: '20px', marginBottom: '4px'}}>
                  {p+" / $"+com.commaizeFloat(price.toFixed(0))}
                </div>
              </div>
            </Popover>
            <div style={{fontSize: 14, color:"#6E6E70", lineHeight: '18px'}}>Est. Closing cost: <span style={{fontWeight: 500}}>${record.closing_cost }</span></div>
          </div>
          :
          <div>---</div>
          }</>
      },
      sorter:(a, b) => {
        if (a.points !== b.points){
          return a.points - b.points;
        } else {
          return a.rate - b.rate;
        }
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend','descend','ascend'],
    },
    {
      title: <span style={{fontSize: 14, color: '#586570', fontWeight: 500, lineHeight: '24px'}}>Compare</span>,
      dataIndex: 'compare',
      key: 'compare',
      width:"80px",
      className: "outerRateColumn",
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={() => handleSelectRow(record)}
        />
      ),
    },
    // {
    //   title: '',
    //   key: 'share',
    //   align: "center",
    //   width:"30px",
    //   render: (_, record) => (
    //     checkAccess([UR_Owner]) && !record.isConnected ? <div><img src="/images/priceengine/addLendersIcon.png" onClick={()=>setShowAddLendersModal(true)} style={{cursor: "pointer", width:32}} /></div> : ""
    //   ),
    // },
    Table.EXPAND_COLUMN,
  ];
  
  const innerColumns=[
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width:"60px",
      className: "innerRateColumn",
      sorter:(a, b) => a.rate - b.rate,
      sortDirections: ['descend','ascend','descend'],
      defaultSortOrder: 'ascend',
      render:(_,record)=>(
        <>
        {!props.isCelebrity && <div style={{fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 500, lineHeight: '20px'}}>{record.rate}%</div>}
        {props.isCelebrity && record.Name.includes("Wh_Ncf") && <div style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":'', fontFamily: 'Inter', fontSize: 16, fontWeight: 500, lineHeight: '20px'}}>{record.rate.toFixed(3)}%</div>}
        {props.isCelebrity && !record.Name.includes("Wh_Ncf") && <>
            <div style={{color:currentTarget==='rate' && record.isConnected ?"#1677FF":'', fontFamily: 'Inter', fontSize: 16, fontWeight: 500, lineHeight: '20px'}}>{getRatesAfterSpecials(record)}%</div>
            {<Popover 
              content={
                <div>
                  <div className="d-flex justify-content-between pl-2 pr-2 bold">
                    <div>Rate before specials:</div>
                    <div>{record.rate.toFixed(3)}%</div>
                  </div>
                  <div className="pl-2 pr-2" style={{color:"green"}}>
                    {Object.entries(props.specialAdjustments).map(([key,value])=>{
                      if (key !== 'Total') {
                        return (    
                            <div style={{display:record.Name.includes("Community") && key === "CRA"? "none": record.Name.includes("Wh_Cnf") && key==="Market"?"none":record.Name.includes("Community")&& key ==="Holiday"?"none":""}}>{value}% for {key} special adjustment</div>
                        )
                      }
                    })}
                  </div>
                  <hr/>
                  <div className="d-flex justify-content-between pl-2 pr-2 bold" style={{color:"#ff2377"}}>
                    <div>Rate after specials:</div>
                    <div>{getRatesAfterSpecials(record)}%</div>
                  </div>
                </div>
              }
            >
              <div className="specialAdjustments">{"Specials detail"}</div>
            </Popover>
            }
          </>
          }
        </>
      ),
    },
    {
      title: 'PMT',
      dataIndex: 'monthlypay',
      key: 'monthlypay',
      width:"90px",
      className: "innerRateColumn",
      render:(_,record)=>(
        <div>
          <div style={{ fontFamily: 'Inter', color: '#222', fontSize: 16, fontWeight: 500, lineHeight: '20px'}}>${commaizeFloat(record.monthlypay)}</div>
        </div>
      ),
    },
    {
      title: 'DTI',
      dataIndex: 'dti',
      key: 'dti',
      width:"90px",
      className: "innerRateColumn",
      render:(_,record)=>(
        <div>
          <div style={{fontSize: 16, color: '#222', fontWeight: 500, lineHeight: '20px'}}>{record.dti.toFixed(0)}%</div>
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img src="/images/rates-price-icon.svg"/> 
          <span>Price</span>
        </div>
      ),
      dataIndex: 'originalprice',
      key: 'originalprice',
      width:"150px",
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
      className: "innerRateColumn",
      render:(_,record)=>{
        let adjustedPrice = useDiscountFormat ? parseFloat(record.originalprice).toFixed(3) +"%" : (100 - parseFloat(record.originalprice)).toFixed(3)
        return <Popover overlayClassName='total-pricing-popover' placement='bottomLeft' content={record.popoverContent} title=''>
            <div style={{display:"flex",alignItems:"center"}}>
              <div style={{textDecoration:"underline", fontFamily: 'Inter', fontSize: 16, fontWeight: 500, lineHeight: '20px', color: !record.isConnected?"": record.originalprice<=0 ? "#208029": "#C21818"}} >
                {adjustedPrice} / ${commaizeFloat(parseFloat(record.originalprice * record.loanAmount*0.01).toFixed(0))}
              </div>
            </div>
        </Popover>
      },
    },
    {
      title: 'LO. Comp.',
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"130px",
      className: "innerRateColumn",
      render:(_,record)=>(
        <div style={{ fontFamily: 'Inter', fontSize: 16, color: '#222',fontWeight: 500, lineHeight: '20px'}}>{record.isConnected ? record.prices.CompensationPoint.toFixed(3) + "% / $"+commaizeFloat(record.compensationprice) : "---"}</div>
      ),
      sorter:(a, b) => a.compensationprice - b.compensationprice,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: <Tooltip title='The total price is the sum of the lock day price, adjustment points, and compensation points.'>Net pricing</Tooltip>,
      dataIndex: 'points',
      key: 'points',
      width:"130px",
      className: "innerRateColumn",
      render:(_,record)=>{
        let p = useDiscountFormat ? parseFloat(record.FHAMIP ? record.points + (100*record.FHAMIP.upfrontMIP/record.loanAmount) : record.points).toFixed(3) + "%" : parseFloat(100 - (record.FHAMIP ? record.points + (100*record.FHAMIP.upfrontMIP/record.loanAmount) : record.points)).toFixed(3)
        let price = record.FHAMIP ? (record.lender_credit + record.FHAMIP.upfrontMIP) : record.lender_credit
        return <>
        {record.isConnected?
          <Popover content={record.netpricingPopContent} title=''>
            <div style={{display:"flex",alignItems:"center"}}>
              <div style={{textDecoration:"underline", fontFamily: 'Inter', fontSize: 16, fontWeight: 500, color: record.lender_credit<=0 ? "#208029":"#C21818", lineHeight: '20px'}}>
                {!record.isConnected ? "---":  p+" / $"+com.commaizeFloat(price.toFixed(0))}
              </div>
            </div>
          </Popover>
          :
          <div>---</div>
        }</>
      },
      sorter:(a, b) => a.points - b.points,
      sortDirections: ['descend','ascend','descend'],
    },
    {
      title: 'Est. closing cost',
      dataIndex: 'compensationprice',
      key: 'compensationprice',
      width:"130px",
      sorter:(a, b) => a.originalprice - b.originalprice,
      sortDirections: ['descend','ascend','descend'],
      className: "innerRateColumn",
      render:(_,record)=>(
        <div style={{fontSize: 16,color:"#222",fontWeight: 500, lineHeight: '20px'}}>${record.closing_cost}</div>
      ),
    },
  ]

  const ineligiblecolumns = [
    {
      title: 'Lender',
      dataIndex: 'lender',
      key: 'lender',
      width:"90px",
      className: "ineligibleColumn",
      render:(_,record)=>(
        <div>
          <strong>{record.Lender}</strong>
        </div>
      ),
    },
    {
      title: 'Ineligible reason',
      dataIndex: 'reason',
      key: 'reason',
      width:"90px",
      className: "ineligibleColumn",
      render:(_,record)=>(
        <div>
          <div className="d-flex"><strong className="mr-2">Lender source problem:</strong> {record.LenderLevelReason?record.LenderLevelReason:"N/A"}</div>
          {record.ProductLevelReasons && record.ProductLevelReasons.map((reason)=>{
            return <div className="d-flex"><strong className="mr-2">Product problem:</strong> {reason.ProductName}-{reason.IneligibleReason}</div>
          })}
        </div>
      ),
    },
  ]

  const calcTargetPricing = (data) => {
    let target = 0
    if (props.targetOptions.includes("lockpoint")) {
      target += data.LockDayPoint
    }
    if (props.targetOptions.includes("adjustment")) {
      target +=  data.AdjustmentPoint
    }
    if (props.targetOptions.includes("compensation")) {
      target +=  data.CompensationPoint
    }
    return parseFloat(target.toFixed(3))
  }

  let mtypes = [];
  let lenderSet = {}
  let lenderRates={}
  let lenderPrices={}
  let ineligibles = []
 
  for (let [name, value] of Object.entries(props.ineligibleProducts)) {
    if (props.selectedlenders.includes(name)) {
      ineligibles.push({
        Lender: name,
        ProductLevelReasons: value.ProductLevelReasons,
        LenderLevelReason: value.LenderLevelReason
      })
    }
  }
  
  for (let mtype in props.allRates) {
    for (let d of props.allRates[mtype]){
      let tmpname= d.Lender+' - '+d.Name
      if (typeof(lenderRates[tmpname]) === "undefined"){
        lenderRates[tmpname]=[]
        lenderPrices[tmpname]=[]
     
      }
      // for finding targeting price
      lenderRates[tmpname].push(d.base_rate);
      lenderPrices[tmpname].push(calcTargetPricing(d.prices));
    }
    mtypes.push(mtype)
    if (typeof lenderSet[mtype] === "undefined") {
      lenderSet[mtype] = new Set()
    }
    
    for (let d of props.allRates[mtype]) {
      lenderSet[mtype].add(d.Lender + "-" + d.Name);
    }
  }

  for (let m in lenderSet) {
    lenderSet[m] = Array.from(lenderSet[m]).sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
  })
  }

  const compareTarget=(target,v,targettype,pname)=>{
    if(targettype === "rate"){
      let differences=lenderRates[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderRates[pname][minidx]
    }
    if(targettype === "price"){
      let differences=lenderPrices[pname].map(element=>Math.abs(element-target));
      let minval=Math.min(...differences);
      let minidx = differences.indexOf(minval);
      return v === lenderPrices[pname][minidx];
    }
  }
  const showCompareModal = () => {
    if (selectedRowKeys.length > 0) {
      setCompareModal(true)
    }
  }
  const compareModalCancel = () => {
    setCompareModal(false)
  }
  const clearSelected = () => {
    setSelectedRowKeys([])
  }
  const cancelSelect = (key) => {
    setSelectedRowKeys((prevSelectedRowKeys) => 
      prevSelectedRowKeys.filter((item) => item !== key)
    )
  }
  const generateTableData = (ratesData, mtype,targettype) => {
    let tmpOuttableonRate = [];
    let tmpOuttableonPrice = [];
    let tmpIntable={}
    let idx=1;
    for (let d of ratesData){
      let mindiff=false
      let tmpname= d.Lender+' - '+d.Name
      let isConnected = (props.connectedLendersMap[d.Lender] && props.connectedLendersMap[d.Lender] === true )? true : false
      if(skipkeywords.includes(d.Mtype.toLowerCase())) {
        continue
      }
      if (typeof(tmpIntable[tmpname]) === "undefined"){
        tmpIntable[tmpname]=[]
      }
      let adjs=[]
      for (let adj in d.Adjustments){
        let i = adjs.length
        adjs.push(
          <div className="d-flex justify-content-between price-calculation-item" style={{ color:"#6E6E70"}}>
            <div className="d-flex">
              <div style={{marginRight: 6, minWidth: 9}}>{i!=0?"+":""}</div>
              <div>{adj}</div>
            </div>
            <div>{parseFloat(d.Adjustments[adj]).toFixed(3)}%</div>
          </div>
        )
      }
      let originalprice = parseFloat(d.prices.LockDayPoint + d.prices.AdjustmentPoint).toFixed(3)
      let Content=(
        <div className="adjusted-price-calculation-card">
          <div className="price-calculation-item-title">Adjusted price calculation</div>
          {adjs}
          <hr />
          <div className="d-flex justify-content-between price-calculation-item">
            <div style={{ marginLeft: 15 }}>Total adjustment points</div>
            <div>{parseFloat(d.prices.AdjustmentPoint).toFixed(3)}%</div>
          </div>
          <div className="d-flex justify-content-between price-calculation-item">
            <div><span style={{ marginRight: 6 }}>+</span>Lock day points</div>
            <div>{parseFloat(d.prices.LockDayPoint).toFixed(3)}%</div>
          </div>
          <hr/>
          <div className="d-flex justify-content-between price-calculation-item" style={{ color: '#222', fontWeight: 500 }}>
            <div>Total</div>
            <div>{originalprice}%</div>
          </div>          
        </div>
      )

      let netpricingContent = (
        <div style={{padding:"15px 10px"}}>
          <div className="mb-4" style={{fontWeight:"500"}}>Net pricing Calculation:</div>
          <div>Total pricing:  {originalprice}% / ${commaizeFloat(parseFloat(originalprice * d.loanAmount*0.01).toFixed(0))}</div>
          <div>Compensation:  {parseFloat(d.prices.CompensationPoint.toFixed(3))}% / ${commaizeFloat(parseFloat(d.prices.CompensationPoint * d.loanAmount*0.01).toFixed(0))}</div>
          {d.FHAMIP !== undefined && <div>Upfront MIP: {100*d.FHAMIP.upfrontMIP/d.loanAmount}% / ${d.FHAMIP.upfrontMIP}</div>}
          <hr/>
          <div>Net pricing:  {d.FHAMIP !== undefined ? (d.points + (100*d.FHAMIP.upfrontMIP/d.loanAmount)).toFixed(3) : d.points.toFixed(3)}% / ${d.FHAMIP !== undefined? commaizeFloat(parseFloat(d.points * d.loanAmount*0.01 + d.FHAMIP.upfrontMIP).toFixed(0)) : commaizeFloat(parseFloat(d.points * d.loanAmount*0.01).toFixed(0))}</div>
        </div>
      )
      
      if(targettype==="rate" && compareTarget(targetrate,d.base_rate,targettype,tmpname)){
        tmpOuttableonRate.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: originalprice,
          compensationprice: parseFloat(d.prices.CompensationPoint).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content,
          netpricingPopContent:netpricingContent,
          fhamip: d.FHAMIP,
          pricedetails: d.prices,
          adjustments:d.Adjustments,
          ...d,
        })
        idx+=1;
        mindiff=true;
      }

  
      if(targettype==="price" && compareTarget(100 - parseFloat(props.targetPrice), calcTargetPricing(d.prices),targettype,tmpname)){
        tmpOuttableonPrice.push({
          key:idx, 
          isConnected: isConnected,
          program:tmpname,
          rate: d.base_rate,
          price: parseFloat(d.lender_credit).toFixed(0),
          originalprice: originalprice,
          compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
          monthlypay: d.monthly_payment,
          dti: d.DTI,
          popoverContent:Content,
          netpricingPopContent:netpricingContent,
          fhamip: d.FHAMIP,
          pricedetails: d.prices,
          adjustments:d.Adjustments,
          ...d,
        })
        idx+=1;
        mindiff=true;
      }
      tmpIntable[tmpname].push({
        program:tmpname,  
        pricedetails: d.prices,
        isConnected: isConnected,
        rate: d.base_rate,
        price: parseFloat(d.lender_credit).toFixed(0),
        originalprice: originalprice,
        compensationprice: parseFloat(d.prices.CompensationPoint * d.loanAmount/100).toFixed(0),
        monthlypay:parseFloat(d.monthly_payment).toFixed(0),
        dti: d.DTI,
        isMin:mindiff,
        popoverContent:Content,
        netpricingPopContent:netpricingContent,
        fhamip: d.FHAMIP,
        ...d,
      })
      
    }
    tmpOuttableonPrice = tmpOuttableonPrice.filter( d => props.selectedlenders.includes(d.program.split(" - ")[0]) )
    tmpOuttableonPrice.sort((a,b)=>a.base_rate-b.base_rate);
    if (targettype==="rate"){
      return [tmpOuttableonRate,tmpIntable];
    }
    if (targettype==="price"){
      return [tmpOuttableonPrice,tmpIntable];
    }
  }
  
  let all={}
  let outdata={}
  let minRate=0;
  const tabdata=[];
  let idx=1;  
  
  let uncheckedProducts=["Agency","Jumbo","FHA","VA"];
  let checkedProducts=[];
  for(let p of props.stdproducts){
    checkedProducts.push(p)
    uncheckedProducts=uncheckedProducts.filter(e=>e!==p)
  }
  let skipkeywords=[]
  uncheckedProducts.map((key)=>{
    skipkeywords= skipkeywords.concat(skipKeywordsMap[key])
  })
  for(let mtype in props.allRates){
    [outdata[mtype],all[mtype]]=generateTableData(props.allRates[mtype],mtype,currentTarget);
    AllresultsCount[mtype]=outdata[mtype].length
    ConnectedresultsCount[mtype]=outdata[mtype].filter(d => d.isConnected).length
    if (outdata[mtype].length > 0) {
      minRate = outdata[mtype][0].base_rate
      outdata[mtype]=[]
      all[mtype] = Object.fromEntries(
        Object.entries(all[mtype]).filter(([key, value]) => 
            props.selectedlenders.includes(key.split(" - ")[0])
        )
      );
      let indx = 1;
      for (let key of Object.keys(all[mtype])){
        let d = all[mtype][key]
        let sample = d.find( ele => ele.rate === minRate)
        if (sample !== undefined){
          outdata[mtype].push({
            key:indx,
            ...sample
          })
        }
        else{
          let results = d.filter(ele => ele.rate > minRate)
          let minObj = results.reduce((min, obj)=> obj.rate < min.rate ? obj : min, results[0])
          outdata[mtype].push({
            key:indx,
            ...minObj
          })
        }
        indx+=1
      }
      AllresultsCount[mtype]=outdata[mtype].length
      ConnectedresultsCount[mtype]=outdata[mtype].filter(d => d.isConnected).length
    }
    tabdata.push({
      tabTitle:typemap[mtype],
      intabledata: all[mtype],
      outtabledata:outdata[mtype],
      tabkey:mtype
    })
    idx+=1
  }

  let targetTab = tabdata.find(tab=>tab.tabkey===props.loanterm)
  if (props.isCelebrity && !props.checkCelebrityLendingArea) {
    targetTab.outtabledata = []
    ineligibles = []
    ineligibles.push({
      Lender: "GMCC Celebrities",
      ProductLevelReasons: [],
      LenderLevelReason: "Subject property address is not in lending area."
    })
    ineligibles.push({
      Lender: "GMCC Celebrity CRA Community Opportunity",
      ProductLevelReasons: [],
      LenderLevelReason: "Subject property address is not in lending area."
    })
  }
  let termMap = {
    fixed30:"30 Year",
    fixed25:"25 Year",
    fixed20:"20 Year",
    fixed15:"15 Year",
    fixed10:"10 Year",
    arm56:"ARM 5/6",
    arm76:"ARM 7/6",
    arm106:"ARM 10/6",
  }
  return (
    <div className="text-left w-100" style={{height:"90%"}}>
      <div className="d-flex justify-content-end  align-items-center my-2" >
        {selectedRows.length >0 && <Button type="primary" className="mr-2" onClick={()=>setShowShareRatesModal(true)}>Share</Button>}
      </div>
      <div className="compare-rates">
        <div className="rate-select-count"><div className="select-count">{selectedRowKeys.length} selected</div>{selectedRowKeys.length > 0 && <div className="clear-btn" onClick={clearSelected}>Clear</div>}</div>
        <div className="compare-rates-line"></div>
        <div className={`rate-compare-btn ${selectedRowKeys.length === 0 ? 'compare-disabled' : ''}`} onClick={showCompareModal}><img src={`/images/rates-compare${selectedRowKeys.length === 0 ? '-disabled' : ''}.svg`}/>Compare rates</div>
      </div>
      {props.eligibilityView === "ineligible"?
        <Table
          style={{width:"100%"}}
          className="ineligibletable rates-table full-table"
          pagination={false}
          columns={ineligiblecolumns}
          rowClassName="ineligibleRow"
          dataSource={ineligibles}
          locale={{emptyText: <div style={{color:"#6E6E70"}}>{'No matched reason'}</div>}}
          scroll={{
            y: checkAccess((UR_PriceEngineUser)) ? props.viewportH- 280 : props.viewportH*0.75
          }}
        />
      :
        <Table
          // style={{width:"100%"}}
          className="outertable rates-table"
          dataSource={props.showConnectedLendersOnly ? targetTab.outtabledata.filter(d => d.isConnected && props.selectedlenders.includes(d.program.split(" - ")[0])) : targetTab.outtabledata.filter(d => props.selectedlenders.includes(d.program.split(" - ")[0]))}
          pagination={false}
          locale={{
            emptyText: 
              props.isCelebrity && !props.checkCelebrityLendingArea?
              <>
              <div style={{color:"#6E6E70", fontSize:"16px", fontWeight:"500"}}>
                Subject property address is not in lending area.
              </div>
              <Button className="backToSearch" onClick={() => props.setShowForm(true)}>Back to Search</Button>
              </>
              :
              <div style={{color:"#6E6E70"}}>
                No matched product found
              </div>
          }}
          columns={maincolumns}
          scroll={{
            y: checkAccess((UR_PriceEngineUser)) ? props.viewportH- 280 : props.viewportH*0.65,
            x: 'max-content'
          }}
          rowClassName={(record,_)=> record.isConnected ? "connectedLenderRow":"unconnectedLenderRow"}
          showSorterTooltip={false}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                {/* {!record.isConnected && <div className="notAddedLenderLabel"><Tooltip className="d-flex align-items-center" title="This wholesale lender is not currently added to your list of lenders. To connect with this lender, click the + button."> Not added yet <QuestionCircleOutlined style={{color:"#fffff", marginLeft:5}} />  </Tooltip></div>} */}
                <div style={{ padding: '2px 10px'}}>
                  <Row gutter={20}>
                    <Col lg={8} span={24} style={{marginBottom: 12}}>
                      <div className="productDetails rate-lender-card">
                        <div style={{fontSize:"14px",color:"#586570", lineHeight: 'normal', fontWeight: 600}}>Loan summary</div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>Loan amount:</div>
                              <div>${commaizeFloat(record.loanAmount)}</div>
                        </div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>Loan term:</div>
                              <div>{termMap[props.loanterm]}</div>
                        </div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>LTV:</div>
                              <div>{props.loanInfo.ltv[0]}%</div>
                        </div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>FICO:</div>
                              <div>{props.loanInfo.fico}</div>
                        </div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>Escrow:</div>
                              <div>{props.loanInfo.escrow===true?"NO":"YES"}</div>
                        </div>
                        <div className="rate-lender-card-item d-flex justify-content-between">
                              <div>Lender origination fee:</div>
                              <div>${record.lender_fee}</div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={16} span={24} style={{marginBottom: 12}}>
                      <div className="productDetails rate-lender-card">
                        {/* <div className="d-flex justify-content-between bold" style={{fontSize:"14px",color:"#586570", borderBottom:"1px solid #DBDCDE"}}>
                          <div>Product details</div>
                          <div style={{fontSize:"12px"}}>*Last Updated on {getCurrentDate()} 09:00</div>
                        </div> */}
                        <div style={{fontSize:"14px",color:"#586570", lineHeight: 'normal', fontWeight: 600}}>Product details</div>
                        <Row style={{ flex: 1 }}>
                          <Col lg={12} span={24}>
                            <div style={{borderRight:"1px solid #CFD4D9",paddingRight: 18, height: '100%', display: 'flex', flexDirection: 'column', gap: 10}}>
                              <div className="rate-lender-card-item d-flex justify-content-between">
                                    <div>Lender</div>
                                    <div>{com.divideStringByCapitalLetter(record.Lender)}</div>
                              </div>
                              <div className="rate-lender-card-item d-flex justify-content-between ">
                                    <div>Underwriting</div>
                                    <div>{record.Aus.join("/")}</div>
                              </div>
                              {typeof record.pricedetails.AdjustmentPoint === "number" && record.pricedetails.AdjustmentPoint !== 0 && (
                                <div className="rate-lender-card-item d-flex justify-content-between">
                                  <div>Applied adjustments</div>
                                  <div>{record.pricedetails.AdjustmentPoint.toFixed(3)}%</div>
                                </div>
                              )}
                              {
                                record.Adjustments && Object.keys(record.Adjustments).length > 0 && Object.entries(record.Adjustments).map(([key, value]) => (
                                  <div className="rate-lender-card-item d-flex justify-content-between" style={{color:"#6E6E70"}}>
                                    <div>{key}</div>
                                    <div>{value}%</div>
                                  </div>
                                ))
                              }
                            </div>
                          </Col>

                          <Col lg={12} span={24}>
                              <div style={{paddingLeft: 18, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                                  <div className="rate-lender-card-item d-flex justify-content-between">
                                      <div>Lock day:</div>
                                      <div>{record.lockDay}</div>
                                  </div>
                                  {targetTab.intabledata[record.program][0].fhamip && <>
                                    <div className="rate-lender-card-item d-flex justify-content-between">
                                        <div>Upfront MIP</div>
                                        <div>${targetTab.intabledata[record.program][0].fhamip.upfrontMIP.toFixed(0)}</div>
                                    </div>
                                    <div className="rate-lender-card-item d-flex justify-content-between">
                                          <div>Monthly MIP</div>
                                          <div>${targetTab.intabledata[record.program][0].fhamip.monthlyMIP.toFixed(0)}</div>
                                    </div>
                                  </>}
                                </div>
                                <div style={{fontSize: 12, color: '#6E6E70', textAlign: 'right'}}>*Last Updated on {getCurrentDate()} 09:00</div>
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                  </Row>
                  <div className="justify-content-center">
                    <Table 
                      className="innertable rates-innertable"
                      pagination={false}
                      showHeader={true}
                      columns={innerColumns}
                      dataSource={targetTab.intabledata[record.program]}
                      rowClassName="innerTableRow"
                      />
                    </div>
                </div>
              </div>
            ),
            rowExpandable: record => !props.mobileHomeLenders.includes(record.Lender),
            expandIcon:({expanded,onExpand, record})=>{
              return(
                props.mobileHomeLenders.includes(record.Lender) ? 
                  ""
                : (
                  <div style={{ textAlign: 'right' }}>
                    <img
                      src={`/images/rates-lenders-${expanded ? 'up' : 'down'}.svg`}
                      style={{cursor: "pointer"}} 
                      onClick={(e)=>{
                        onExpand(record,e)
                      }}
                    />
                  </div>
                )
              )
            }
          }}
        />
      }

    <Modal
      open={showAddLenderModal}
      onCancel={()=>setShowAddLendersModal(false)}
      centered
      footer={[
        <Button key="back" onClick={()=>setShowAddLendersModal(false)}>
          Cancel
        </Button>,
      ]}
      width={1000}
    >
      <Lenders />
    </Modal>
    <Modal
      title="Share Rates"
      open={showShareRateModal}
      onCancel={()=>setShowShareRatesModal(false)}
      centered
      footer={[
        <Button type="primary" onClick={()=>setShowShareRatesModal(false)}>
          Share
        </Button>,
      ]}
    >
      <div className="p-3">
        <div className="title-5">Borrower Email</div>
        <Input placeholder="email@example.com"/>

        <div className="title-5">Selected rates</div>
        {/* {displaySelectedRates(selectedRows)} */}
        
        <div className="title-5">Additonal information (optional)</div>
        <TextArea rows={4} placeholder="Enter your infomation..." maxLength={6} />


      </div>
    
    </Modal>
    <CompareProduct
      open={compareModal}
      tableData={targetTab.outtabledata.filter(item => selectedRowKeys.includes(item.key))}
      cancelSelect={cancelSelect}
      onCancel={compareModalCancel}
    >
    </CompareProduct>      
    </div>
  );
}



